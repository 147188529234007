import { TextField, Checkbox } from '@mui/material'
import { DynamicContent, MultiselectList, Pagination } from 'shared/components'
import { PaginationProps } from 'shared/hooks'
import { useTranslation } from 'react-i18next'
import {
  ButtonWrapper,
  PaginationWrapper,
  SearchWrapper,
  Container,
  ListWrapper,
  CheckboxWrapper,
} from './EditList.style'
import { LoadingButton } from '@mui/lab'
import { ExtendedOption } from 'shared/types'

interface EditListDialogProps<T, K> {
  query: string
  setQuery: (value: string) => void
  dataLoading: boolean
  options: ExtendedOption<T, K>[]
  selectedValues: K[]
  handleItemClick: (value: K) => void
  paginationProps: PaginationProps
  isSaving: boolean
  onSave: () => void
  saveDisabled: boolean
  onToggleAll: (checked: boolean) => void
  allSelected: boolean
}

const EditList = <T, K>({
  query,
  setQuery,
  dataLoading,
  options,
  selectedValues,
  handleItemClick,
  paginationProps,
  isSaving,
  onSave,
  saveDisabled,
  onToggleAll,
  allSelected,
}: EditListDialogProps<T, K>) => {
  const { t } = useTranslation()

  return (
    <Container>
      <SearchWrapper>
        <TextField
          value={query}
          onChange={e => setQuery(e.target.value)}
          size="small"
        />
      </SearchWrapper>
      <CheckboxWrapper
        control={
          <Checkbox
            edge="start"
            tabIndex={-1}
            checked={allSelected}
            onChange={(e, checked) => onToggleAll(checked)}
          />
        }
        label={t('selectAll')}
      />
      <ListWrapper>
        <DynamicContent
          loading={dataLoading}
          textAlign="center"
          displayContent={options.length}
        >
          <MultiselectList
            options={options}
            selectedValues={selectedValues}
            onItemClick={handleItemClick}
          />
        </DynamicContent>
      </ListWrapper>
      <PaginationWrapper>
        <Pagination {...paginationProps} />
      </PaginationWrapper>
      <ButtonWrapper>
        <LoadingButton
          variant="contained"
          onClick={onSave}
          loading={isSaving}
          disabled={saveDisabled}
        >
          {t('save')}
        </LoadingButton>
      </ButtonWrapper>
    </Container>
  )
}

export default EditList
