import {
  isBefore,
  isAfter,
  isSameDay,
  addMinutes,
  startOfDay,
  addDays,
  isEqual,
} from 'date-fns'
import {
  DateTimeFormatter,
  LocalDateTime,
  nativeJs,
  ZoneId,
} from '@js-joda/core'
import '@js-joda/timezone'

const DEFAULT_PATTERN = 'd.MM.yyyy HH:mm'

export const isDateInRange = (date: Date, fromDate: Date, toDate: Date) => {
  const isDateBefore = isBefore(date, toDate)
  const isDateAfter = isAfter(date, fromDate)

  return (
    (isDateAfter && isDateBefore) ||
    (isSameDay(date, fromDate) && isSameDay(date, toDate))
  )
}

export const generateMinuteIntervals = (
  options: {
    min?: Date
    max?: Date
    interval?: number
  } = {}
): Date[] => {
  const {
    min = startOfDay(new Date()),
    max = startOfDay(addDays(new Date(), 1)),
    interval = 15,
  } = options

  const timeIntervals: Date[] = [min]
  let timeInterval = addMinutes(min, interval)
  while (isEqual(timeInterval, max) || isBefore(timeInterval, max)) {
    timeIntervals.push(timeInterval)
    timeInterval = addMinutes(timeInterval, interval)
  }
  return timeIntervals
}

export const dateToLocalString = (date: Date) => {
  return LocalDateTime.from(nativeJs(date)).toString()
}

export const convertToTimezoneDateString = (date: string, timezone: string) => {
  const localDate = LocalDateTime.parse(date)
  const timeZoneId = ZoneId.of(timezone)
  const zonedDate = localDate.atZone(timeZoneId)
  const formatedDate = zonedDate.format(
    DateTimeFormatter.ofPattern(DEFAULT_PATTERN)
  )
  const timeOffset = zonedDate.format(DateTimeFormatter.ofPattern('x'))
  const displayDate = `${formatedDate} (GMT${timeOffset})`
  return displayDate
}

export const stringToLocalDate = (date: string) => {
  return new Date(date)
}

export const dateToTimezoneDateString = (date: Date, timezone: string) => {
  const localDate = LocalDateTime.from(nativeJs(date))
  const timeZoneId = ZoneId.of(timezone)
  const zonedDate = localDate.atZone(timeZoneId)
  const formatedDate = zonedDate.format(
    DateTimeFormatter.ofPattern(DEFAULT_PATTERN)
  )
  const timeOffset = zonedDate.format(DateTimeFormatter.ofPattern('x'))
  const displayDate = `${formatedDate} (GMT${timeOffset})`
  return displayDate
}

export const dateToFormattedString = (
  date: Date,
  pattern: string = DEFAULT_PATTERN
) => {
  const localDate = LocalDateTime.from(nativeJs(date))
  const formatedDate = localDate.format(DateTimeFormatter.ofPattern(pattern))
  return formatedDate
}
